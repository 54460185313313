
import {
  mdiChevronDown,
  mdiRepeat,
  mdiMenu,
  mdiHome,
  mdiHumanMaleBoardPoll,
  mdiResponsive,
  mdiAccountTie,
  mdiApplicationBracesOutline,
  mdiAccountDetails,
  mdiArchiveArrowUp,
  mdiEmail,
  mdiMenuDown,
  mdiSearchWeb,
} from '@mdi/js'

export default {
  data() {
    return {
      mdiApplicationBracesOutline,
      mdiMenuDown,
      mdiAccountTie,
      mdiResponsive,
      mdiHumanMaleBoardPoll,
      mdiHome,
      mdiArchiveArrowUp,
      mdiMenu,
      mdiAccountDetails,
      mdiRepeat,
      mdiEmail,
      mdiChevronDown,
      mdiSearchWeb,
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      dialog: false,
      mobileLinks: [
        {
          action: mdiHome,
          items: [{ title: 'Home Page', link: '/' }],
          title: 'Home Page',
        },
        {
          action: mdiHumanMaleBoardPoll,
          items: [
            {
              title: 'Catalog',
              link: '/course-catalog',
            },
            {
              title: 'Accessibility',
              link: '/courses/accessibility',
            },
            {
              title: 'Associations',
              link: '/courses/associations',
            },
            { title: 'Adobe', link: '/course-catalog?tab=adobe' },
            {
              title: 'Government',
              link: '/courses/government',
            },
            {
              title: 'Great Series',
              link: '/courses/great-series',
            },
            { title: 'Microsoft', link: '/course-catalog?tab=microsoft' },
            {
              title: 'Professional Skills',
              link: '/course-catalog?tab=professional-skills',
            },
            {
              title: 'Specialty',
              link: '/course-catalog?tab=programming-specialty',
            },
          ],
          title: 'Courses',
        },
        {
          action: mdiResponsive,
          items: [
            {
              title: 'Software Training',
              link: '/software-training',
            },
            {
              title: 'Software Consulting',
              link: '/software-consulting-services',
            },
          ],
          title: 'Software',
        },
        {
          action: mdiAccountTie,
          items: [
            {
              title: 'Professional Skills Training',
              link: '/professional-skills-training',
            },
            // {
            //   title: 'Coaching',
            //   link: '/coaching',
            // },
            // {
            //   title: 'Consulting',
            //   link: '/consulting',
            // },
          ],
          title: 'Professional Skills',
        },
        {
          action: mdiApplicationBracesOutline,
          items: [
            { title: 'Databases & Web App', link: '/databases-and-web-apps' },
          ],
          title: 'Databases & Web App',
        },
        {
          action: mdiAccountDetails,
          items: [
            { title: 'Our GCT Advantage', link: '/gctadvantage' },
            { title: 'FAQ', link: '/faq' },
            { title: 'Our Training Centre', link: '/our-training-centre' },
            { title: 'Our Team', link: '/our-team' },
            { title: 'Our Clients', link: '/our-clients' },
          ],
          title: 'About Us',
        },
        {
          action: mdiArchiveArrowUp,
          items: [
            { title: 'All FREE Resources ', link: '/free-resources' },
            { title: 'Webinars', link: '/webinars' },
            { title: 'Downloads', link: '/free-resources#free-downloads' },

            { title: 'Podcast', link: '/podcast' },
            {
              title: 'Blog',
              link: '/blog',
            },
          ],
          title: 'Free Resources',
        },
        {
          action: mdiEmail,
          items: [{ title: 'Contact Us', link: '/contact-us' }],
          title: 'Contact Us',
        },
      ],
      searchInput: '',
    }
  },
  computed: {},
  created() {
    this.$nuxt.$on('clearSearch', () => this.clearSearch())
  },
  mounted() {
    this.checkForIpadScreen()
  },
  methods: {
    blogCheck(text) {
      return text.includes('https://')
    },
    blurForm($event) {
      $event.target.blur()
    },
    checkForIpadScreen() {
      if (process.browser) {
        if (window.innerWidth < 1260) {
          document
            .getElementById('desktop')
            .setAttribute('style', 'display:none;')

          document.getElementById('ipad').setAttribute('style', 'display:flex;')
        } else {
          document
            .getElementById('desktop')
            .setAttribute('style', 'display:flex;')

          document.getElementById('ipad').setAttribute('style', 'display:none;')
        }
      }
    },
    hideDropdown(e) {
      e.target.closest('.dropdown').style.pointerEvents = 'none'

      setTimeout(() => {
        e.target.closest('.dropdown').style.pointerEvents = 'auto'
      }, 800)
    },
    async callAPI() {
      await this.$store.dispatch('getSiteMapItems')
    },
    updateSearch() {
      setTimeout(() => {
        this.$nuxt.$emit('updateSearch')
      }, 250)
    },
    clearSearch() {
      setTimeout(() => {
        this.searchInput = ''
        this.dialog = false
      }, 250)
    },
  },
}
