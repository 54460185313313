
export default {
  props: ['searchInput'],
  data() {
    return {
      siteMapItemsResults: [],
    }
  },
  computed: {
    coursesPagesResults() {
      const partialResults1 = this.siteMapItemsResults
        .filter(
          (s) => s.category.includes('Course') && s.title.includes('Level')
        )
        .map((c) => c)

      const partialResults2 = this.siteMapItemsResults
        .filter(
          (s) => s.category.includes('Course') && !s.title.includes('Level')
        )
        .map((c) => c)

      return partialResults1.concat(partialResults2)
    },

    landingPagesResults() {
      return this.siteMapItemsResults.filter((s) =>
        s.category.includes('Landing')
      )
    },

    blogArticlesResults() {
      return this.siteMapItemsResults.filter((s) => s.category.includes('Blog'))
    },

    podcastResults() {
      return this.siteMapItemsResults.filter((s) =>
        s.category.includes('Podcast')
      )
    },

    staticPagesResults() {
      return this.siteMapItemsResults.filter((s) =>
        s.category.includes('Static')
      )
    },

    webinarsResults() {
      return this.siteMapItemsResults.filter((s) =>
        s.category.includes('Webinars')
      )
    },
    freeDownloadsResults() {
      return this.siteMapItemsResults.filter((s) =>
        s.category.includes('Free Downloads')
      )
    },
    siteMapItemsFromStore() {
      return this.$store.getters.getSiteMapItemsFromStore
    },
  },
  created() {
    this.$nuxt.$on('updateSearch', () => this.updateSearch())
  },
  methods: {
    blogCheck(text) {
      return text.includes('https://')
    },

    updateSearch() {
      if (this.searchInput.length > 1) {
        this.siteMapItemsResults = this.siteMapItemsFromStore.filter(
          (s) =>
            s.title.toLowerCase().includes(this.searchInput.toLowerCase()) ||
            s.tags?.toLowerCase().includes(this.searchInput.toLowerCase())
        )
      }
    },
    clearSearch() {
      this.$nuxt.$emit('clearSearch')
    },
  },
}
