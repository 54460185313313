
import {
  mdiFaceAgent,
  mdiCardAccountMailOutline,
  mdiArchiveLock,
  mdiBriefcaseAccountOutline,
  mdiSitemapOutline
} from '@mdi/js'

export default {
  data() {
    return {
      mdiFaceAgent,
      mdiCardAccountMailOutline,
      mdiArchiveLock,
      mdiBriefcaseAccountOutline,
      mdiSitemapOutline
    }
  },
  computed: {
    getYear() {
      return new Date().getFullYear()
    },
  },
}
