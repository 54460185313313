
export default {
  scrollToTop: false,
  data() {
    return {
      fixed: false,
      isHidden: true,
    };
  },
  head() {
    return {
      script: [
        {
          src: '/bingAdds.js',
          body: true,
          defer: true,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://greatcanadiantraining.ca${this.$route.path}`,
        }
      ]
    }
  },

  onMounted() {
    if (process.client && window) {
      window.history.scrollRestoration = "manual";
    }
  },
  computed: {
    languageStatus() {
      try {
        if (
          this.$store.state.languageState.find(
            (l) => l.english === decodeURIComponent(this.$route.path)
          ).french
        ) {
          return true;
        } else {
          return false;
        }
      } catch {
        return false;
      }
    },
    frenchURL() {
      try {
        if (
          this.$store.state.languageState.find(
            (l) => l.english === decodeURIComponent(this.$route.path)
          ).french
        ) {
          return this.$store.state.languageState.find(
            (l) => l.english === decodeURIComponent(this.$route.path)
          ).french;
        } else {
          return "/fr";
        }
      } catch {
        return "/fr";
      }
    },
  },
  methods: {
    activeFrench() {
      if (!localStorage.getItem("gct-fr")) {
        localStorage.setItem("gct-fr", "true");
      }
    },

    logoEntered() {
      this.isHidden = false;
    },
  },
};
