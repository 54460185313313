import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3e5ccb2c = () => interopDefault(import('..\\pages\\blog\\index.vue' /* webpackChunkName: "pages/blog/index" */))
const _d2eac91a = () => interopDefault(import('..\\pages\\careers\\index.vue' /* webpackChunkName: "pages/careers/index" */))
const _3a954d46 = () => interopDefault(import('..\\pages\\checkout\\index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _158b7844 = () => interopDefault(import('..\\pages\\coaching\\index.vue' /* webpackChunkName: "pages/coaching/index" */))
const _5cb5e796 = () => interopDefault(import('..\\pages\\consulting\\index.vue' /* webpackChunkName: "pages/consulting/index" */))
const _50615cfe = () => interopDefault(import('..\\pages\\contact-us\\index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _0ae4bbf6 = () => interopDefault(import('..\\pages\\course-catalog\\index.vue' /* webpackChunkName: "pages/course-catalog/index" */))
const _d6d29194 = () => interopDefault(import('..\\pages\\databases-and-web-apps\\index.vue' /* webpackChunkName: "pages/databases-and-web-apps/index" */))
const _79274172 = () => interopDefault(import('..\\pages\\faq\\index.vue' /* webpackChunkName: "pages/faq/index" */))
const _f3bf2100 = () => interopDefault(import('..\\pages\\fr\\index.vue' /* webpackChunkName: "pages/fr/index" */))
const _f3c0c522 = () => interopDefault(import('..\\pages\\free-accessibility-ribbons\\index.vue' /* webpackChunkName: "pages/free-accessibility-ribbons/index" */))
const _10423370 = () => interopDefault(import('..\\pages\\free-resources\\index.vue' /* webpackChunkName: "pages/free-resources/index" */))
const _9752d94e = () => interopDefault(import('..\\pages\\gctadvantage\\index.vue' /* webpackChunkName: "pages/gctadvantage/index" */))
const _3efff89e = () => interopDefault(import('..\\pages\\live-support\\index.vue' /* webpackChunkName: "pages/live-support/index" */))
const _6d59b493 = () => interopDefault(import('..\\pages\\managers-hr-professionals-and-it-departments-training\\index.vue' /* webpackChunkName: "pages/managers-hr-professionals-and-it-departments-training/index" */))
const _ee543d3e = () => interopDefault(import('..\\pages\\our-clients\\index.vue' /* webpackChunkName: "pages/our-clients/index" */))
const _486aa964 = () => interopDefault(import('..\\pages\\our-team\\index.vue' /* webpackChunkName: "pages/our-team/index" */))
const _8b40c30a = () => interopDefault(import('..\\pages\\our-training-centre\\index.vue' /* webpackChunkName: "pages/our-training-centre/index" */))
const _4d1df4fc = () => interopDefault(import('..\\pages\\payment\\index.vue' /* webpackChunkName: "pages/payment/index" */))
const _6049c9c4 = () => interopDefault(import('..\\pages\\podcast\\index.vue' /* webpackChunkName: "pages/podcast/index" */))
const _e9ab3bee = () => interopDefault(import('..\\pages\\previous-webinars\\index.vue' /* webpackChunkName: "pages/previous-webinars/index" */))
const _4edede97 = () => interopDefault(import('..\\pages\\priority-management-terms-and-conditions\\index.vue' /* webpackChunkName: "pages/priority-management-terms-and-conditions/index" */))
const _0fdc8955 = () => interopDefault(import('..\\pages\\privacy-policy\\index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _f0d614e6 = () => interopDefault(import('..\\pages\\professional-skills-training\\index.vue' /* webpackChunkName: "pages/professional-skills-training/index" */))
const _062b2269 = () => interopDefault(import('..\\pages\\room-rental-live\\index.vue' /* webpackChunkName: "pages/room-rental-live/index" */))
const _49415773 = () => interopDefault(import('..\\pages\\sitemap\\index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _056cdf3d = () => interopDefault(import('..\\pages\\software-consulting-services\\index.vue' /* webpackChunkName: "pages/software-consulting-services/index" */))
const _5ae1bb88 = () => interopDefault(import('..\\pages\\software-training\\index.vue' /* webpackChunkName: "pages/software-training/index" */))
const _30175082 = () => interopDefault(import('..\\pages\\special-event\\index.vue' /* webpackChunkName: "pages/special-event/index" */))
const _26bb1d58 = () => interopDefault(import('..\\pages\\terms-and-conditions\\index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _35d6fcce = () => interopDefault(import('..\\pages\\training-strategy-session\\index.vue' /* webpackChunkName: "pages/training-strategy-session/index" */))
const _f1011324 = () => interopDefault(import('..\\pages\\us\\index.vue' /* webpackChunkName: "pages/us/index" */))
const _35d36263 = () => interopDefault(import('..\\pages\\webinars\\index.vue' /* webpackChunkName: "pages/webinars/index" */))
const _fe954276 = () => interopDefault(import('..\\pages\\checkout\\index_v2.vue' /* webpackChunkName: "pages/checkout/index_v2" */))
const _fcbdf184 = () => interopDefault(import('..\\pages\\courses\\associations\\index.vue' /* webpackChunkName: "pages/courses/associations/index" */))
const _6c84017f = () => interopDefault(import('..\\pages\\courses\\government\\index.vue' /* webpackChunkName: "pages/courses/government/index" */))
const _6745d684 = () => interopDefault(import('..\\pages\\courses\\training-packages\\index.vue' /* webpackChunkName: "pages/courses/training-packages/index" */))
const _3e5e1d89 = () => interopDefault(import('..\\pages\\fr\\contactez-nous\\index.vue' /* webpackChunkName: "pages/fr/contactez-nous/index" */))
const _4e3860ee = () => interopDefault(import('..\\pages\\fr\\cours-catalogue\\index.vue' /* webpackChunkName: "pages/fr/cours-catalogue/index" */))
const _9fe17a84 = () => interopDefault(import('..\\pages\\fr\\faq\\index.vue' /* webpackChunkName: "pages/fr/faq/index" */))
const _86bece10 = () => interopDefault(import('..\\pages\\fr\\formation-aux-logiciels\\index.vue' /* webpackChunkName: "pages/fr/formation-aux-logiciels/index" */))
const _5085cabd = () => interopDefault(import('..\\pages\\fr\\formation-des-compétences\\index.vue' /* webpackChunkName: "pages/fr/formation-des-compétences/index" */))
const _18e2beb0 = () => interopDefault(import('..\\pages\\fr\\lavantage-gct\\index.vue' /* webpackChunkName: "pages/fr/lavantage-gct/index" */))
const _93f79d32 = () => interopDefault(import('..\\pages\\fr\\nos-clients\\index.vue' /* webpackChunkName: "pages/fr/nos-clients/index" */))
const _854a55f8 = () => interopDefault(import('..\\pages\\fr\\notre-centre-de-formation\\index.vue' /* webpackChunkName: "pages/fr/notre-centre-de-formation/index" */))
const _88414338 = () => interopDefault(import('..\\pages\\fr\\notre-equipe\\index.vue' /* webpackChunkName: "pages/fr/notre-equipe/index" */))
const _83ba0416 = () => interopDefault(import('..\\pages\\fr\\paiement\\index.vue' /* webpackChunkName: "pages/fr/paiement/index" */))
const _3d6c51dd = () => interopDefault(import('..\\pages\\fr\\politique-de-confidentialite\\index.vue' /* webpackChunkName: "pages/fr/politique-de-confidentialite/index" */))
const _6b3d51a5 = () => interopDefault(import('..\\pages\\fr\\support-en-direct\\index.vue' /* webpackChunkName: "pages/fr/support-en-direct/index" */))
const _dff52654 = () => interopDefault(import('..\\pages\\ottawa\\microsoft-excel-training-courses\\index.vue' /* webpackChunkName: "pages/ottawa/microsoft-excel-training-courses/index" */))
const _042989a2 = () => interopDefault(import('..\\pages\\private\\client-section\\index.vue' /* webpackChunkName: "pages/private/client-section/index" */))
const _370b9c28 = () => interopDefault(import('..\\pages\\private\\client-update-password\\index.vue' /* webpackChunkName: "pages/private/client-update-password/index" */))
const _2600cb12 = () => interopDefault(import('..\\pages\\private\\live-support\\index.vue' /* webpackChunkName: "pages/private/live-support/index" */))
const _aa479cdc = () => interopDefault(import('..\\pages\\private\\login-page\\index.vue' /* webpackChunkName: "pages/private/login-page/index" */))
const _ca2be668 = () => interopDefault(import('..\\pages\\private\\previous-webinars-new-design\\index.vue' /* webpackChunkName: "pages/private/previous-webinars-new-design/index" */))
const _c69aaf40 = () => interopDefault(import('..\\pages\\private\\webinars-new-design\\index.vue' /* webpackChunkName: "pages/private/webinars-new-design/index" */))
const _0353f8ca = () => interopDefault(import('..\\pages\\webinars\\registration\\index.vue' /* webpackChunkName: "pages/webinars/registration/index" */))
const _55508e8c = () => interopDefault(import('..\\pages\\fr\\formations\\associations-professionnelles\\index.vue' /* webpackChunkName: "pages/fr/formations/associations-professionnelles/index" */))
const _d93126c2 = () => interopDefault(import('..\\pages\\fr\\formations\\formation-pour-les-agences-gouvernementales\\index.vue' /* webpackChunkName: "pages/fr/formations/formation-pour-les-agences-gouvernementales/index" */))
const _411d47ce = () => interopDefault(import('..\\pages\\fr\\ottawa\\cours-de-formation-sur-microsoft-excel\\index.vue' /* webpackChunkName: "pages/fr/ottawa/cours-de-formation-sur-microsoft-excel/index" */))
const _f34d8894 = () => interopDefault(import('..\\pages\\fr\\paiement\\index_v2.vue' /* webpackChunkName: "pages/fr/paiement/index_v2" */))
const _2acd3be0 = () => interopDefault(import('..\\pages\\fr\\cours\\_id\\index.vue' /* webpackChunkName: "pages/fr/cours/_id/index" */))
const _ab775808 = () => interopDefault(import('..\\pages\\fr\\formations\\_id\\index.vue' /* webpackChunkName: "pages/fr/formations/_id/index" */))
const _6b7b74f5 = () => interopDefault(import('..\\pages\\private\\cours\\_id\\index.vue' /* webpackChunkName: "pages/private/cours/_id/index" */))
const _5026c2f0 = () => interopDefault(import('..\\pages\\private\\course\\_id\\index.vue' /* webpackChunkName: "pages/private/course/_id/index" */))
const _4da1f142 = () => interopDefault(import('..\\pages\\private\\event-videos\\_id.vue' /* webpackChunkName: "pages/private/event-videos/_id" */))
const _4e40bc3a = () => interopDefault(import('..\\pages\\blog\\_url\\index.vue' /* webpackChunkName: "pages/blog/_url/index" */))
const _c5c2f82e = () => interopDefault(import('..\\pages\\course\\_id\\index.vue' /* webpackChunkName: "pages/course/_id/index" */))
const _87a092ec = () => interopDefault(import('..\\pages\\courses\\_id\\index.vue' /* webpackChunkName: "pages/courses/_id/index" */))
const _5ac1f097 = () => interopDefault(import('..\\pages\\webinars\\_id\\index.vue' /* webpackChunkName: "pages/webinars/_id/index" */))
const _0189104c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _3e5ccb2c,
    name: "blog"
  }, {
    path: "/careers",
    component: _d2eac91a,
    name: "careers"
  }, {
    path: "/checkout",
    component: _3a954d46,
    name: "checkout"
  }, {
    path: "/coaching",
    component: _158b7844,
    name: "coaching"
  }, {
    path: "/consulting",
    component: _5cb5e796,
    name: "consulting"
  }, {
    path: "/contact-us",
    component: _50615cfe,
    name: "contact-us"
  }, {
    path: "/course-catalog",
    component: _0ae4bbf6,
    name: "course-catalog"
  }, {
    path: "/databases-and-web-apps",
    component: _d6d29194,
    name: "databases-and-web-apps"
  }, {
    path: "/faq",
    component: _79274172,
    name: "faq"
  }, {
    path: "/fr",
    component: _f3bf2100,
    name: "fr"
  }, {
    path: "/free-accessibility-ribbons",
    component: _f3c0c522,
    name: "free-accessibility-ribbons"
  }, {
    path: "/free-resources",
    component: _10423370,
    name: "free-resources"
  }, {
    path: "/gctadvantage",
    component: _9752d94e,
    name: "gctadvantage"
  }, {
    path: "/live-support",
    component: _3efff89e,
    name: "live-support"
  }, {
    path: "/managers-hr-professionals-and-it-departments-training",
    component: _6d59b493,
    name: "managers-hr-professionals-and-it-departments-training"
  }, {
    path: "/our-clients",
    component: _ee543d3e,
    name: "our-clients"
  }, {
    path: "/our-team",
    component: _486aa964,
    name: "our-team"
  }, {
    path: "/our-training-centre",
    component: _8b40c30a,
    name: "our-training-centre"
  }, {
    path: "/payment",
    component: _4d1df4fc,
    name: "payment"
  }, {
    path: "/podcast",
    component: _6049c9c4,
    name: "podcast"
  }, {
    path: "/previous-webinars",
    component: _e9ab3bee,
    name: "previous-webinars"
  }, {
    path: "/priority-management-terms-and-conditions",
    component: _4edede97,
    name: "priority-management-terms-and-conditions"
  }, {
    path: "/privacy-policy",
    component: _0fdc8955,
    name: "privacy-policy"
  }, {
    path: "/professional-skills-training",
    component: _f0d614e6,
    name: "professional-skills-training"
  }, {
    path: "/room-rental-live",
    component: _062b2269,
    name: "room-rental-live"
  }, {
    path: "/sitemap",
    component: _49415773,
    name: "sitemap"
  }, {
    path: "/software-consulting-services",
    component: _056cdf3d,
    name: "software-consulting-services"
  }, {
    path: "/software-training",
    component: _5ae1bb88,
    name: "software-training"
  }, {
    path: "/special-event",
    component: _30175082,
    name: "special-event"
  }, {
    path: "/terms-and-conditions",
    component: _26bb1d58,
    name: "terms-and-conditions"
  }, {
    path: "/training-strategy-session",
    component: _35d6fcce,
    name: "training-strategy-session"
  }, {
    path: "/us",
    component: _f1011324,
    name: "us"
  }, {
    path: "/webinars",
    component: _35d36263,
    name: "webinars"
  }, {
    path: "/checkout/index_v2",
    component: _fe954276,
    name: "checkout-index_v2"
  }, {
    path: "/courses/associations",
    component: _fcbdf184,
    name: "courses-associations"
  }, {
    path: "/courses/government",
    component: _6c84017f,
    name: "courses-government"
  }, {
    path: "/courses/training-packages",
    component: _6745d684,
    name: "courses-training-packages"
  }, {
    path: "/fr/contactez-nous",
    component: _3e5e1d89,
    name: "fr-contactez-nous"
  }, {
    path: "/fr/cours-catalogue",
    component: _4e3860ee,
    name: "fr-cours-catalogue"
  }, {
    path: "/fr/faq",
    component: _9fe17a84,
    name: "fr-faq"
  }, {
    path: "/fr/formation-aux-logiciels",
    component: _86bece10,
    name: "fr-formation-aux-logiciels"
  }, {
    path: "/fr/formation-des-comp%C3%A9tences",
    component: _5085cabd,
    name: "fr-formation-des-compétences"
  }, {
    path: "/fr/lavantage-gct",
    component: _18e2beb0,
    name: "fr-lavantage-gct"
  }, {
    path: "/fr/nos-clients",
    component: _93f79d32,
    name: "fr-nos-clients"
  }, {
    path: "/fr/notre-centre-de-formation",
    component: _854a55f8,
    name: "fr-notre-centre-de-formation"
  }, {
    path: "/fr/notre-equipe",
    component: _88414338,
    name: "fr-notre-equipe"
  }, {
    path: "/fr/paiement",
    component: _83ba0416,
    name: "fr-paiement"
  }, {
    path: "/fr/politique-de-confidentialite",
    component: _3d6c51dd,
    name: "fr-politique-de-confidentialite"
  }, {
    path: "/fr/support-en-direct",
    component: _6b3d51a5,
    name: "fr-support-en-direct"
  }, {
    path: "/ottawa/microsoft-excel-training-courses",
    component: _dff52654,
    name: "ottawa-microsoft-excel-training-courses"
  }, {
    path: "/private/client-section",
    component: _042989a2,
    name: "private-client-section"
  }, {
    path: "/private/client-update-password",
    component: _370b9c28,
    name: "private-client-update-password"
  }, {
    path: "/private/live-support",
    component: _2600cb12,
    name: "private-live-support"
  }, {
    path: "/private/login-page",
    component: _aa479cdc,
    name: "private-login-page"
  }, {
    path: "/private/previous-webinars-new-design",
    component: _ca2be668,
    name: "private-previous-webinars-new-design"
  }, {
    path: "/private/webinars-new-design",
    component: _c69aaf40,
    name: "private-webinars-new-design"
  }, {
    path: "/webinars/registration",
    component: _0353f8ca,
    name: "webinars-registration"
  }, {
    path: "/fr/formations/associations-professionnelles",
    component: _55508e8c,
    name: "fr-formations-associations-professionnelles"
  }, {
    path: "/fr/formations/formation-pour-les-agences-gouvernementales",
    component: _d93126c2,
    name: "fr-formations-formation-pour-les-agences-gouvernementales"
  }, {
    path: "/fr/ottawa/cours-de-formation-sur-microsoft-excel",
    component: _411d47ce,
    name: "fr-ottawa-cours-de-formation-sur-microsoft-excel"
  }, {
    path: "/fr/paiement/index_v2",
    component: _f34d8894,
    name: "fr-paiement-index_v2"
  }, {
    path: "/fr/cours/:id",
    component: _2acd3be0,
    name: "fr-cours-id"
  }, {
    path: "/fr/formations/:id",
    component: _ab775808,
    name: "fr-formations-id"
  }, {
    path: "/private/cours/:id",
    component: _6b7b74f5,
    name: "private-cours-id"
  }, {
    path: "/private/course/:id",
    component: _5026c2f0,
    name: "private-course-id"
  }, {
    path: "/private/event-videos/:id?",
    component: _4da1f142,
    name: "private-event-videos-id"
  }, {
    path: "/blog/:url",
    component: _4e40bc3a,
    name: "blog-url"
  }, {
    path: "/course/:id",
    component: _c5c2f82e,
    name: "course-id"
  }, {
    path: "/courses/:id",
    component: _87a092ec,
    name: "courses-id"
  }, {
    path: "/webinars/:id",
    component: _5ac1f097,
    name: "webinars-id"
  }, {
    path: "/",
    component: _0189104c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
