// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/Helvetica.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n  font-family: 'Helvetica';\r\n  font-display: swap;\r\n  src: local('Helvetica'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\r\n}\r\n\r\nhtml {\r\n  font-family: 'Helvetica';\r\n}\r\n\r\nbody {\r\n  padding: 0;\r\n  margin: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow-x: clip !important;\r\n}\r\n\r\n.container {\r\n  padding: 0px !important;\r\n}\r\n\r\n/*Pages transition*/\r\n.page-enter-active,\r\n.page-leave-active {\r\n  transition: all 0.4s ease;\r\n}\r\n\r\n.page-enter-active {\r\n  min-height: 600px;\r\n}\r\n\r\n.page-enter,\r\n.page-leave-active {\r\n  opacity: 0;\r\n}\r\n\r\nul {\r\n  list-style: none;\r\n}\r\n\r\nul li::before {\r\n  content: '•';\r\n  color: #b12028;\r\n  display: inline-block;\r\n  position: relative;\r\n  top: 6px;\r\n  width: 0.6em;\r\n  margin-left: -0.6em;\r\n  font-size: 1.8em;\r\n}\r\n\r\nol li::marker {\r\n  font-weight: bold;\r\n  color: #154388;\r\n}\r\n\r\n.iconLabeled{\r\n  padding: 5px;\r\n  cursor: pointer;\r\n  display:flex;\r\n  flex-flow: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.iconLabel{\r\n  text-align: center;\r\n  font-size: 0.8em;\r\n}\r\n.iconContainer{\r\n  display:inline-block\r\n}\r\n\r\ndiv.widget-visible {\r\n  z-index: 9 !important;\r\n}\r\n\r\na.no-line {\r\n  text-decoration: none;\r\n}\r\n.additionButtonCheckoutForSingleOption{\r\n  margin-top: 12px;\r\n    position: absolute;\r\n    right: 23px;\r\n    width: 14%;\r\n}\r\n@media (max-width: 600px) {\r\n  .additionButtonCheckoutForSingleOption{\r\n    margin-top: auto;\r\n    position: inherit;\r\n    right:inherit;\r\n    width: 100%;\r\n  }\r\n  .additionButtonCheckoutForSingleOption .knowMoreButtons{\r\n    width:100%;\r\n    border-radius: 5px;\r\n  }\r\n}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
