
import {
  mdiChevronDown,
  mdiRepeat,
  mdiMenu,
  mdiHome,
  mdiHumanMaleBoardPoll,
  mdiResponsive,
  mdiAccountTie,
  mdiApplicationBracesOutline,
  mdiAccountDetails,
  mdiArchiveArrowUp,
  mdiEmail,
  mdiMenuDown,
  mdiSearchWeb,
} from '@mdi/js'
export default {
  data() {
    return {
      mdiChevronDown,
      mdiRepeat,
      mdiMenu,
      mdiHome,
      mdiHumanMaleBoardPoll,
      mdiResponsive,
      mdiAccountTie,
      mdiApplicationBracesOutline,
      mdiAccountDetails,
      mdiArchiveArrowUp,
      mdiEmail,
      mdiMenuDown,
      mdiSearchWeb,
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      dialog: false,
      mobileLinks: [
        {
          action: mdiHome,
          items: [{ title: "Page d'accueil", link: '/' }],
          title: "Page d'accueil",
        },
        {
          action: mdiHumanMaleBoardPoll,
          items: [
            {
              title: 'Catalogue',
              link: '/fr/cours-catalogue?',
            },
            {
              title: "L'accessibilité",
              link: '/fr/formations/laccessibilite',
            },
            {
              title: 'Associations',
              link: '/fr/formations/associations-professionnelles',
            },

            {
              title: 'Gouvernement',
              link: '/fr/formations/formation-pour-les-agences-gouvernementales',
            },
            { title: 'Microsoft', link: '/fr/cours-catalogue?tab=microsoft' },
            {
              title: 'Compétences professionnelles',
              link: '/fr/formation-des-compétences',
            },
          ],
          title: 'Cours',
        },
        {
          action: mdiResponsive,
          items: [
            {
              title: 'Formation sur les logiciels',
              link: '/fr/formation-aux-logiciels',
            },
          ],
          title: 'Formation sur les logiciels',
        },
        {
          action: mdiAccountTie,
          items: [
            {
              title: 'Compétences professionnelles',
              link: '/fr/formation-des-compétences',
            },
          ],
          title: 'Compétences professionnelles',
        },
        {
          action: mdiAccountDetails,
          items: [
            {
              title: 'FAQ',
              link: '/fr/faq',
            },
            {
              title: 'Les avantages de Great Canadian',
              link: '/fr/lavantage-gct',
            },
            {
              title: 'Notre centre de formation',
              link: '/fr/notre-centre-de-formation',
            },
            { title: 'Notre équipe', link: '/fr/notre-equipe' },
            { title: 'Nos clients', link: '/fr/nos-clients' },
          ],
          title: 'À propos de nous',
        },
        {
          action: mdiEmail,
          items: [{ title: 'Contactez-nous', link: '/fr/contactez-nous' }],
          title: 'Contactez-nous',
        },
      ],
      searchInput: '',
    }
  },
  computed: {},
  created() {
    this.$nuxt.$on('clearSearch', () => this.clearSearch())
  },
  mounted() {
    this.checkForIpadScreen()
  },
  methods: {
    blogCheck(text) {
      return text.includes('https://')
    },
    blurForm($event) {
      $event.target.blur()
    },
    checkForIpadScreen() {
      if (process.browser) {
        if (window.innerWidth < 1260) {
          document
            .getElementById('desktop')
            .setAttribute('style', 'display:none;')

          document.getElementById('ipad').setAttribute('style', 'display:flex;')
        } else {
          document
            .getElementById('desktop')
            .setAttribute('style', 'display:flex;')

          document.getElementById('ipad').setAttribute('style', 'display:none;')
        }
      }
    },
    hideDropdown(e) {
      e.target.closest('.dropdown').style.pointerEvents = 'none'

      setTimeout(() => {
        e.target.closest('.dropdown').style.pointerEvents = 'auto'
      }, 800)
    },
    async callAPI() {
      await this.$store.dispatch('getSiteMapItems')
    },
    updateSearch() {
      setTimeout(() => {
        this.$nuxt.$emit('updateSearch')
      }, 250)
    },
    clearSearch() {
      setTimeout(() => {
        this.searchInput = ''
        this.dialog = false
      }, 500)
    },
  },
}
