
import {
  mdiPhone,
  mdiEmailOutline,
  mdiWeb,
  mdiCart,
  mdiRepeat,
  mdiAccountOutline,
  mdiEye,
  mdiEyeOff,
} from '@mdi/js'

export default {
  props: {
    language: {
      type: Boolean,
      default: true,
    },
    frenchurl: {
      type: String,
      default: '/fr',
    },
  },
  async asyncData({ $kaiden, route }) {
    const courseDetails = await $kaiden.$get(
      `/Website/course-detail/${route.params.id}`
    )
    return { courseDetails }
  },
  data() {
    return {
      environment: process.env.NODE_ENV,
      mdiRepeat,
      mdiAccountOutline,
      mdiCart,
      mdiWeb,
      mdiEmailOutline,
      mdiPhone,
      mdiEye,
      mdiEyeOff,
      updatePassword: false,
      updatePasswordEmailSent: false,
      loginPassword: '',
      loginEmail: '',
      dialog: false,
      hideLogo: false,
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      isHidden: true,
      showPass: false,
      password: '',
      cartEvents: [],
      errorMessage: '',
      items: ['English', 'French'],
      valid: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password is Required.',
        (v) => v?.length >= 8 || 'Minimum 8 characters',
      ],
    }
  },
  computed: {
    shoppingCart() {
      return this.cartEvents.length
    },
    Connected() {
      return this.$store.getters.getLoginStatus
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.refreshCartEvents()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    if (process.browser) {
      this.environment = window.location.origin
    }

    this.$root.$on('updateCart', () => {
      this.refreshCartEvents()
    })
    let cartOverallParticipantsList = []
    if (localStorage.getItem('cartOverallParticipantsList') !== null)
      cartOverallParticipantsList = JSON.parse(
        localStorage.getItem('cartOverallParticipantsList')
      )

    localStorage.setItem(
      'cartOverallParticipantsList',
      JSON.stringify(cartOverallParticipantsList)
    )
  },
  created() {
    // adds the event listener function that will open registration cart
    this.$nuxt.$on('toogleDrawerEvent', () => {
      this.refreshCartEvents()
      this.toogleDrawer()
    })

    // adds the event listener function for updating shopping cart items
    this.$nuxt.$on('eventsUpdate', (e) => {
      this.cartEvents = e
    })
  },
  methods: {
    openLoginDialog() {
      this.errorMessage = ''
      this.loginEmail = ''
      this.loginPassword = ''
      this.dialog = true
    },
    closeLoginDialog() {
      this.dialog = false
      this.loginEmail = ''
      this.loginPassword = ''
      this.errorMessage = ''
    },
    async calculateSHA256Hash(data) {
      const encoder = new TextEncoder()
      const dataBuffer = encoder.encode(data)
      const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('')
      return hashHex
    },
    async UpdatePasswordEmail() {
      if (this.$refs.loginForm.validate()) {
        const passwordEncrypted = await this.calculateSHA256Hash(
          this.loginPassword
        )

        const request = {
          Email: this.loginEmail,
          passwordEncrypted,
        }

        await this.$kaiden
          .$post('/Website/validate-login', request)
          .then((result) => {
            if (isNaN(result)) {
              this.errorMessage = result

              if (result === 'The password is incorrect') {
                const emailToUpdatePassword = this.loginEmail.toString()

                console.log(emailToUpdatePassword)

                this.$kaiden
                  .$post(
                    '/Website/update-login-password-email?Email=' +
                      emailToUpdatePassword
                  )
                  .then(() => {
                    this.errorMessage = ''
                    this.updatePassword = false
                    this.updatePasswordEmailSent = true
                    this.$refs.loginForm.reset()
                    this.$refs.loginForm.resetValidation()
                  })

                // this.errorMessage = ''
                // this.updatePassword = false
                // this.updatePasswordEmailSent = true
                // this.$refs.loginForm.reset()
                // this.$refs.loginForm.resetValidation()
              } else if (result === 'The user was not found') {
                this.updatePassword = false
              }
            }
          })
      }
    },
    goToMyTrainingPage() {
      this.$router.push(`/private/client-section/`)
    },
    async validateLogin() {
      if (this.$refs.loginForm.validate()) {
        const passwordEncrypted = await this.calculateSHA256Hash(
          this.loginPassword
        )

        const request = {
          Email: this.loginEmail,
          passwordEncrypted,
        }

        this.$kaiden
          .$post('/Website/validate-login', request)
          .then((result) => {
            if (isNaN(result)) {
              this.errorMessage = result

              if (result === 'The password is incorrect') {
                this.updatePassword = true
              } else if (result === 'The user was not found') {
                this.updatePassword = false
              }

              return
            }

            this.updatePassword = false
            this.errorMessage = ''

            localStorage.setItem('accountId', result)
            this.closeLoginDialog()
            this.$store.commit('grantAccess')
            this.$router.push({
              path: '/private/client-section/',
            })
          })
      }
    },
    activeFrench() {
      this.$emit('gct-fr')
    },
    async loadEventDetails(eventId) {
      try {
        return await this.$kaiden.$get('/Website/event-detail/' + eventId)
      } catch (error) {
        alert(error)
      }
      return {}
    },
    CartEventHasEventsInIt(events) {
      return events.length > 0
    },
    formatDate(date) {
      window.console.log(date)
      const options = { year: 'numeric', month: 'short', day: 'numeric' }
      return new Date(date).toLocaleDateString('en-CA', options)
    },
    getListEventIdsFromCartEvents() {
      const listEventIds = []
      this.cartEvents.forEach((events) => {
        if (this.CartEventHasEventsInIt(events.Events)) {
          const eventId = events.Events[0].eventId
          if (!listEventIds.includes(eventId)) {
            listEventIds.push(eventId)
          }
        }
      })
      return listEventIds
    },
    goToCheckOut() {
      if (process.client) {
        const currentEventsOnCart = JSON.parse(
          localStorage.getItem('cartEvents')
        )

        if (currentEventsOnCart.length > 0) {
          let cartTotalRevenue = 0
          currentEventsOnCart.forEach((ce, i) => {
            cartTotalRevenue += ce.PriceOfEvent * ce.Qty
          })

          const conversionValue = cartTotalRevenue * 0.2

          this.$gtm.push({
            event: 'navigation_to_checkout',
            currency: 'CAD',
            value: conversionValue,
          })
        }
      }

      this.$router.push({ path: '/checkout' })
    },
    updateCartEvents() {
      localStorage.setItem('cartEvents', JSON.stringify(this.cartEvents))
    },
    refreshCartEvents() {
      if (localStorage.getItem('cartEvents') === null)
        localStorage.setItem('cartEvents', JSON.stringify([]))

      this.$nextTick(() => {
        this.cartEvents = JSON.parse(localStorage.getItem('cartEvents'))
        const listEventIds = this.getListEventIdsFromCartEvents()

        listEventIds.forEach((eventId) => {
          const eventDetail = this.loadEventDetails(eventId)
          eventDetail.then((Detail) => {
            this.cartEvents.forEach((cartEvent) => {
              if (cartEvent.Events[0].eventId === Detail.id) {
                cartEvent.certificateText = Detail.certificateText
                cartEvent.language = Detail.language
                cartEvent.titleCourse = Detail.titleCourse
                cartEvent.sessions = Detail.sessions
              }
            })
            this.updateCartEvents()
          })
        })
      })
    },
    handleScroll() {
      if (window.scrollY > 96) {
        this.hideLogo = true
      }
    },

    toogleDrawer() {
      this.rightDrawer = !this.rightDrawer
    },
    logoEntered() {
      this.$emit('logo-entered')
    },
  },
}
