
import { mdiPhone, mdiEmailOutline, mdiWeb, mdiCart, mdiRepeat } from '@mdi/js'

export default {
  props: {
    language: {
      type: Boolean,
      default: true,
    },
    englishurl: {
      type: String,
      default: '/en',
    },
  },
  async asyncData({ $kaiden, route }) {
    const courseDetails = await $kaiden.$get(
      `/Website/course-detail/${route.params.id}`
    )
    return { courseDetails }
  },
  data() {
    return {
      mdiRepeat,
      mdiCart,
      mdiWeb,
      mdiEmailOutline,
      mdiPhone,
      hideLogo: false,
      dialog: false,
      clipped: false,
      drawer: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      isHidden: true,
      showPass: false,
      password: '',
      cartEvents: [],
      items: ['English', 'French'],
    }
  },
  computed: {},
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.refreshCartEvents()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)

    // removes event listener function that will open registration cart
    // this.$nuxt.$off('toogleDrawer')
  },
  mounted() {
    this.$root.$on('updateCart', () => {
      this.refreshCartEvents()
    })
    let cartOverallParticipantsList = []
    if (localStorage.getItem('cartOverallParticipantsList') !== null)
      cartOverallParticipantsList = JSON.parse(
        localStorage.getItem('cartOverallParticipantsList')
      )
    localStorage.setItem(
      'cartOverallParticipantsList',
      JSON.stringify(cartOverallParticipantsList)
    )
  },
  created() {
    // adds the event listener function that will open registration cart
    this.$nuxt.$on('toogleDrawerEvent', () => {
      this.refreshCartEvents()
      this.toogleDrawer()
    })
  },

  methods: {
    inactiveFrench() {
      this.$emit('gct-fr-not')
    },
    async loadEventDetails(eventId) {
      try {
        return await this.$kaiden.$get('/Website/event-detail/' + eventId)
      } catch (error) {
        alert(error)
      }
      return {}
    },
    CartEventHasEventsInIt(events) {
      return events.length > 0
    },
    formatDate(date) {
      window.console.log(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString('en-CA', options)
    },
    getListEventIdsFromCartEvents() {
      const listEventIds = []
      this.cartEvents.forEach((events) => {
        if (this.CartEventHasEventsInIt(events.Events)) {
          const eventId = events.Events[0].eventId
          if (!listEventIds.includes(eventId)) {
            listEventIds.push(eventId)
          }
        }
      })
      return listEventIds
    },
    updateCartEvents() {
      localStorage.setItem('cartEvents', JSON.stringify(this.cartEvents))
    },
    refreshCartEvents() {
      if (localStorage.getItem('cartEvents') === null)
        localStorage.setItem('cartEvents', JSON.stringify([]))

      this.$nextTick(() => {
        this.cartEvents = JSON.parse(localStorage.getItem('cartEvents'))
        const listEventIds = this.getListEventIdsFromCartEvents()

        listEventIds.forEach((eventId) => {
          const eventDetail = this.loadEventDetails(eventId)
          eventDetail.then((Detail) => {
            this.cartEvents.forEach((cartEvent) => {
              if (cartEvent.Events[0].eventId === Detail.id) {
                cartEvent.certificateText = Detail.certificateText
                cartEvent.language = Detail.language
                cartEvent.titleCourse = Detail.titleCourse
                cartEvent.sessions = Detail.sessions
              }
            })
            this.updateCartEvents()
          })
        })
      })
    },
    handleScroll() {
      if (window.scrollY > 96) {
        this.hideLogo = true
      }
    },
    toogleDrawer() {
      this.rightDrawer = !this.rightDrawer
    },
    logoEntered() {
      this.$emit('logo-entered')
    },
  },
}
