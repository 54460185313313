import { render, staticRenderFns } from "./FrenchLinks.vue?vue&type=template&id=2bc67cae&scoped=true&"
import script from "./FrenchLinks.vue?vue&type=script&lang=js&"
export * from "./FrenchLinks.vue?vue&type=script&lang=js&"
import style0 from "./FrenchLinks.vue?vue&type=style&index=0&id=2bc67cae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc67cae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchResultsFR: require('C:/home/site/wwwroot/components/search/ResultsFR.vue').default})
