
export default {
  layout(context) {
   if(context.route.path.includes('/fr/')) return 'frenchLayout'
   return  'default';
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
  computed: {
    language() {
      if (this.$nuxt.$route.path.includes('/fr/')) return true
      else return false
    },
  },
  methods: {
   
  }
}
