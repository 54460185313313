import { render, staticRenderFns } from "./frenchLayout.vue?vue&type=template&id=3521540a&"
import script from "./frenchLayout.vue?vue&type=script&lang=js&"
export * from "./frenchLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageImageHeader: require('C:/home/site/wwwroot/components/homepage/ImageHeader.vue').default,HomepageFrenchHeader: require('C:/home/site/wwwroot/components/homepage/FrenchHeader.vue').default,NavigationBackTop: require('C:/home/site/wwwroot/components/navigation/BackTop.vue').default,HomepageFrenchFooter: require('C:/home/site/wwwroot/components/homepage/FrenchFooter.vue').default})
