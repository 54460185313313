
export default {

  scrollToTop: false,
  data() {
    return {
      fixed: false,
      isHidden: true,
    }
  },
  head() {
    return {
      script: [
        {
          src: '/bingAdds.js',
          body: true,
          defer: true,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://greatcanadiantraining.ca${this.$route.path}`,
        }
      ]
    }
  },
  computed: {
    languageStatus() {
      try {
        if (
          this.$store.state.languageState.find(
            (l) => l.french === decodeURIComponent(this.$route.path)
          ).english
        ) {
          return true
        } else {
          return true
        }
      } catch {
        return true
      }
    },
    englishURL() {
      try {
        if (
          this.$store.state.languageState.find(
            (l) => l.french === decodeURIComponent(this.$route.path)
          ).english
        ) {
          return this.$store.state.languageState.find(
            (l) => l.french === decodeURIComponent(this.$route.path)
          ).english
        } else {
          return '/'
        }
      } catch {
        return '/'
      }
    },
  },
  methods: {
    inactiveFrench() {
      if (localStorage.getItem('gct-fr')) {
        localStorage.removeItem('gct-fr')
      }
    },
      logoEntered() {
      this.isHidden = false;
    },
  },
}
