import colors from 'vuetify/es5/util/colors'

export default {
  optionsPath: './vuetify.options.js',
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#154388',
        accent: '#f28a04',
        error: '#B12028',
        darkgray: '#535353',
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        success: colors.green.accent3
      }
    }
  },
}