export const state = () => ({
  // VueX State
  languageState: [
    {
      english: '/software-training',
      french: '/fr/formation-aux-logiciels',
    },
    {
      english: '/ottawa/microsoft-excel-training-courses',
      french: '/fr/ottawa/cours-de-formation-sur-microsoft-excel',
    },
    {
      english: '/our-training-centre',
      french: '/fr/notre-centre-de-formation',
    },
    {
      english: '/software-consulting-services',
      french: '/fr/conseil-en-logiciel',
    },
    {
      english: '/faq',
      french: '/fr/faq',
    },
    {
      english: '/professional-skills-training',
      french: '/fr/formation-des-compétences',
    },
    {
      english: '/courses/associations',
      french: '/fr/formations/associations-professionnelles',
    },
    {
      english: '/courses/microsoft-excel',
      french: '/fr/formations/microsoft-excel-en-francais',
    },
    {
      english: '/courses/microsoft-onenote',
      french: '/fr/formations/microsoft-onenote-en-francais',
    },
    {
      english: '/courses/microsoft-outlook',
      french: '/fr/formations/microsoft-outlook-en-francais',
    },
    {
      english: '/courses/microsoft-powerpoint',
      french: '/fr/formations/microsoft-powerpoint-en-francais',
    },
    {
      english: '/courses/microsoft-power-bi',
      french: '/fr/formations/formation-en-microsoft-power-bi-en-francais',
    },
    {
      english: '/courses/microsoft-teams',
      french: '/fr/formations/microsoft-teams-en-francais',
    },
    {
      english: '/courses/microsoft-word',
      french: '/fr/formations/microsoft-word-en-francais',
    },
    {
      english: '/courses/communication-skills',
      french: '/fr/formations/techniques-de-communication',
    },
    {
      english: '/courses/leadership-skills',
      french: '/fr/formations/competences-de-leadership',
    },
    {
      english: '/courses/team-building',
      french: '/fr/formations/travail-en-equipe',
    },
    {
      english: '/courses/time-management-training',
      french: '/fr/formations/la-gestion-du-temps',
    },
    {
      english: '/courses/accessibility',
      french: '/fr/formations/laccessibilite',
    },
    {
      english: '/courses/administrative-professionals',
      french: '/fr/formations/les-professionnels-de-ladministration',
    },
    {
      english: '/our-clients',
      french: '/fr/nos-clients',
    },
    {
      english: '/',
      french: '/fr',
    },
    {
      english: '/course-catalog',
      french: '/fr/cours-catalogue',
    },
    {
      english: '/courses/government',
      french: '/fr/formations/formation-pour-les-agences-gouvernementales',
    },
    {
      english: '/our-team',
      french: '/fr/notre-equipe',
    },
    {
      english: '/gctadvantage',
      french: '/fr/lavantage-gct',
    },
    {
      english: '/contact-us',
      french: '/fr/contactez-nous',
    },
    {
      english: '/coaching',
      french: '/fr/mentorat',
    },
    {
      english: '/checkout',
      french: '/fr/paiement',
    },
    {
      english: '/privacy-policy',
      french: '/fr/politique-de-confidentialite',
    },
    {
      english: '/private/course/microsoft-excel-level-1-basic',
      french: '/private/cours/excel-niveau-1-de-base',
    },
  ],
  siteMapItems: [],
  authenticated: false,
})

export const getters = {
  getSiteMapItemsFromStore(state) {
    return state.siteMapItems
  },

  getLoginStatus(state) {
    return state.authenticated
  },
}

export const mutations = {
  setSiteMapItems(state, data) {
    state.siteMapItems = data
  },
  addUrl(state, data) {
    state.languageState.push(data)
  },
  grantAccess(state) {
    state.authenticated = true
  },
  removeAccess(state) {
    state.authenticated = false
  },
}

export const actions = {
  async getSiteMapItems(context) {
    const data = await this.$kaiden.$get(
      '/website/sitemap-items-search-results'
    )
    context.commit('setSiteMapItems', data)
  },
}
