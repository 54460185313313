
import { mdiArrowUpDropCircle } from '@mdi/js'

export default {
  data() {
    return {
      mdiArrowUpDropCircle,
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const mybutton = document.getElementById('myBtn')

      if (document.documentElement.scrollTop > 800) {
        mybutton.style.opacity = '0.8'
      } else {
        mybutton.style.opacity = '0'
      }
    },
    BackTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
