const redirects = require('../static/redirects.json') // update to your file path

export default function(req, res, next) {
  // find the redirect if it exists where the from === the requested url
  const lengthRawHeader = req.rawHeaders.length
 
  const localURL = req.rawHeaders.find((r) => {
    let result = true;
    result = r.includes("greatcanadiantraining.ca/") // live website
    if(!result)
      result = r.includes("gc-website-stage.azurewebsites.net/") // stage website
  
    return result
  })

const localURLBis = req.rawHeaders[lengthRawHeader - 1]

const redirect = redirects.find((r) => {
  return r.from?.replace(/\/+$/, "") === req.url?.replace(/\/+$/, "") || r.from?.replace(/\/+$/, "") === localURL?.replace(/\/+$/, "") || r.from?.replace(/\/+$/, "") === localURLBis?.replace(/\/+$/, "")
})

  // If it exists, redirect the page with a 302 response else carry on
  if (redirect) {
    res.writeHead(302, { Location: redirect.to })
    res.end()
  } else {
    next()
  }
}