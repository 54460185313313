export default function ({ $axios }, inject) {
    // Create a custom axios instance
    const kaiden = $axios.create({
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      }
    })
  
    // Set baseURL to something different
    kaiden.setBaseURL('https://kaiden-api.azurewebsites.net/')

    // kaiden.setBaseURL('https://localhost:44391/')
  
    // Inject to context as $api
    inject('kaiden', kaiden)
  }