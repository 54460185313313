import { render, staticRenderFns } from "./FrenchHeader.vue?vue&type=template&id=129be35b&scoped=true&"
import script from "./FrenchHeader.vue?vue&type=script&lang=js&"
export * from "./FrenchHeader.vue?vue&type=script&lang=js&"
import style0 from "./FrenchHeader.vue?vue&type=style&index=0&id=129be35b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129be35b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationFrenchLinks: require('C:/home/site/wwwroot/components/navigation/FrenchLinks.vue').default})
